import { CoreActionsUnion, CoreActionTypes } from '../actions/core.action';
import { ReuploadActionTypes, ReuploadActionUnions } from '../actions/reupload.action';

export interface State {
  currentState: string;
}

export const initailState: State = {
  currentState: null
};

export function reducer(state = initailState, action: CoreActionsUnion | ReuploadActionUnions): State {
  switch (action.type) {
    case ReuploadActionTypes.UpdateKycImageSuccess:
    case ReuploadActionTypes.FetchProfileSuccess: {
      return {
        ...state,
        currentState: action.payload.reupload.current_action
      };
    }
    default: {
      return state;
    }
  }
}

export const getCurrentState = (state: State) => state.currentState;
