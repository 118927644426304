import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found-page',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="text-center p-5">
      <br>
      <div class="extra-large-text">
        <img class="img-fluid mx-auto d-block" src="../../assets/images/no-offer.png" alt="Wrong page">
      </div>
      <br/><br/>
      <div>
        <p class="medium-text">Hey! It looks like your information is not correct.</p>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        text-align: center;
      }
    `,
  ],
})
export class NotFoundPageComponent {}
