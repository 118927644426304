import { Action } from '@ngrx/store';

export enum CoreActionTypes {
  ReadDatabase = '[Core] Read Database',
  ReadDatabaseSuccess = '[Core] Read Database Success',
  ReadDatabaseFailure = '[Core] Read Database Failure'
}

export class ReadDatabase implements Action {
  readonly type = CoreActionTypes.ReadDatabase;

  constructor(public payload: any) {}
}

export class ReadDatabaseSuccess implements Action {
  readonly type = CoreActionTypes.ReadDatabaseSuccess;

  constructor(public payload: any) {}
}

export class ReadDatabaseFailure implements Action {
  readonly type = CoreActionTypes.ReadDatabaseFailure;

  constructor(public payload: any) {}
}

export type CoreActionsUnion =
  | ReadDatabase
  | ReadDatabaseSuccess
  | ReadDatabaseFailure;
