import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppState } from '../../store/app.states';
import { select, Store } from '@ngrx/store';
import { CurrentActions } from '../../constants';
import { Observable, Subscription } from 'rxjs';
import * as appState from '../../store/app.states';

@Component({
  selector: 'app-wait-without-action',
  templateUrl: './wait-without-action.component.html',
  styleUrls: ['./wait-without-action.component.css']
})

export class WaitWithoutActionComponent implements OnInit {

  imagePath: string;
  headerText: string;
  descText: string;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.initializeTexts();
  }

  initializeTexts(): void {
      this.imagePath = 'assets/images/thumbs_up.svg';
      this.headerText = 'Thank you!';
      this.descText = 'We will get back to you..';
  }
}
