<div class="mt-5">
    <div class="box" style="margin-bottom: 6rem!important;">
    <div class="inner-box text-center pt-3">
      <h4>Upload Shop Photo</h4>
    </div>


<div  class="text-center" style="width: 100% !important">
  <webcam *ngIf="!webcamImage" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"  [allowCameraSwitch]="false" [switchCamera]="nextWebcamObservable" (initError)="handleInitError($event)"> </webcam>
   <!-- <video [hidden]="true" #video autoplay></video> -->

    
    <img  class="snapshot" *ngIf="webcamImage" width="90%" [src]="webcamImage.imageAsDataUrl"/>
  </div>
  <app-button-footer *ngIf="!webcamImage && videoOn" (buttonClicked)="triggerSnapshot()" [buttonText]="'CAPTURE'" (subButtonClicked)="showNextWebcam(true)" 
  [subButtonText]="'Rotate Camera'" [showSubButton]="webcamImage"></app-button-footer>
  <app-button-footer *ngIf="webcamImage" (buttonClicked)="onSubmitClicked($event)" [buttonText]="'CONTINUE'"  (subButtonClicked)="removeImage()"
  [subButtonText]="'Remove'" [showSubButton]="webcamImage"></app-button-footer>
  </div>
  <div style="height: 1rem;"></div>
<!-- </div> -->
<div>

