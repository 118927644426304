import { Action } from '@ngrx/store';

export enum RedirectActionTypes {
  NotFoundPage = '[Redirect] Not Found Page',
  ErrorPage = '[Redirect] Error Page'
}

export class NotFoundPage {
  readonly type = RedirectActionTypes.NotFoundPage;
}

export class ErrorPage {
  readonly type = RedirectActionTypes.ErrorPage;
}

export type RedirectActionUnions =
  | NotFoundPage
  | ErrorPage;
