import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorDialog} from '../models/utils';
import { CoreService } from '../services/core.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CloseErrorDialog } from '../store/actions/error-dialog.action';
import { AppState } from '../store/app.states';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-error-dialog',
  template: `
    <div class="fixed-top">
      <div class="row">
        <div class="col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4 fixed-content">
          <mat-card class="p-0" style="margin: 0rem 2rem;">
            <mat-card-content class="text-center">
              <p class="mt-3 pt-2" [innerHTML]="message"></p>
              <div mat-dialog-actions class="float-root">
                <button class="btn-ripple btn btn-primary" mat-button (click)="closeButtonClicked()" cdkFocusInitial>
                  Ok
                </button>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .fixed-top {
      position: fixed;
      top: 50%;
      right: 0;
      left: 0;
      z-index: 1030;
      width: 100%;
      margin: auto;
      text-align: center;
    }
    .btn-primary {
      background: #EB1B69 !important;
      border-radius: 15px;
      width: 100%;
      padding: 1rem 0;
      margin: 1rem;
      text-align: center;
      font-family: MuseoRegular900;
      font-style: normal;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      color: #FFFFFF !important;
    }
    .fixed-content{
      flex: 1 1 auto;
      padding: 1rem;
    }
    p{
      font-family: MuseoRegular;
      font-size: 16px;
      text-align: center;
      color: red;
      margin: 2vh 0 4vh;
      font-weight: bold;
    }
      `
  ],
})
export class ErrorDialogComponent {
  public message: any;

  constructor(
    private coreService: CoreService,
    private sanitizer: DomSanitizer,
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string) {
      this.message = this.sanitizer.bypassSecurityTrustHtml(this.data);
    }

  closeButtonClicked(): void {
    this.dialogRef.close();
    this.store.dispatch(new CloseErrorDialog());
  }

}


