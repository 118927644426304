export class ApiConstants {
  public static API_GET_KYC_STATUS = '/reupload/'; // GET
  public static API_UPDATE_KYC = '/reupload/'; // PATCH
  public static  API_INVOID_URL = '/fetch_rekyc_invoid_url/'; // GET;
}

export class PathConstants {
  public static REUPLOAD_KYC = 'reupload';
}

export class CurrentActions {
  public static REUPLOAD_PAN = 'reupload_pan';
  public static REUPLOAD_PROFILE_IMAGE = 'reupload_photo';
  public static REUPLOAD_ADDRESS_PROOF_FRONT = 'reupload_address_proof_front';
  public static REUPLOAD_ADDRESS_PROOF_BACK = 'reupload_address_proof_back';
  public static THANK_YOU = 'thank_you';
  public static SHOP_PHOTO = 'reupload_shop_photo';
  public static SHOP_VIDEO = 'reupload_shop_video';
  public static DIGILOCKER_CONSENT = 'digilocker_consent';
}

export class LocalStorageConstants {
  public static PROFILE_LOADED = 'profile_loaded';
  public static LOGGED_IN_KEY = 'logged_in';
  public static LOGGED_IN_VALUE = 'true';
  public static MOBILE_KEY = 'mobile';
  public static PARTNER_SLUG_KEY = 'partner_slug';
  public static CUSTOMER_CODE_KEY = 'customer_code';
  public static PHOTO_PRESENT = 'photo_url_present';
  public static PAN_PRESENT = 'pan_url_present';
  public static ADDRESS_PROOF_FRONT_PRESENT = 'address_proof_front_url_present';
  public static ADDRESS_PROOF_BACK_PRESENT = 'address_proof_back_url_present';
  public static ADDRESS_PROOF_TYPE_PRESENT = 'address_proof_type_present';
}

export class Constants {
  public static IMAGE_MAX_SIZE_IN_KB = 4096;
  public static ADDRESS_PROOF_TYPE_DRIVING_LICENSE = 'driving_license';
  public static ADDRESS_PROOF_TYPE_VOTER_ID = 'voter_id';
  public static IDENTITY_PROOF_TYPE_SELFIE = 'selfie';
  public static IDENTITY_PROOF_TYPE_PAN = 'pan';
  public static REUPLOAD_STATUS = 'reupload_status';
  public static SUCCESSFUL_STATUS = 'successful';
  public static PARTNER_CODE = 'partner_code';
  public static MOBILE = 'mobile';
}
