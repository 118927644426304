<div class="col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4 app-body" *ngIf="currentPage">
  <div class="box" style="margin-bottom: 6rem!important;">
    <div class="text-center">
      <br /><br />
      <div class="m-vmargin" style="margin-top: 5rem;" *ngIf="!showAddressChoiceScreen">
        <!-- <div class="box" style="margin-bottom: 40px !important;">
          <div class="inner-box">
            <div class="box-detail">
              <div class="box-img">
                <img src="/assets/images/aadhar.jpg" alt="aadhar card" />
              </div>
              <div class="box-text">
                <ul>
                  <li>1.Enter your Aadhar No.</li>
                  <li>2.Enter OTP/PIN verification (received on your Aadhar linked mobile number)</li>
                </ul>
              </div>
            </div>
          </div>
          <button class=" btn btn-primary small-btn" type="submit" (click)="digilockerFlow()"> Continue </button>
        </div> -->
        <div class="box" style="margin-bottom: 8rem!important;">
          <div class="inner-box">
            <div class="box-detail">
              <div class="box-img">
                <img src="/assets/images/pan.png" alt="pan card" />
              </div>
              <div class="box-text">
                <ul>
                  <li>Uploading Driving Licence or voter ID card
                    (Front & Back) or Aadhar card (Front & Back)
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <button class=" btn btn-primary small-btn" style="margin-top: 2rem;" type="submit" (click)="rekycFlow(actionForThisPage)"> Continue </button>
        </div>
      </div>
      <div *ngIf="showAddressProof && showChoiceScreen" style="margin-top: 2rem;">
        <h5>Select Address Type</h5>
        <div class="m-vmargin" style="margin-top: 1rem;">
          <select (change)="submit($event.target.value)" class="form-control" [(ngModel)]="selected">
            <option [value]="option.value" *ngFor="let option of options">{{option.name}}</option>
          </select>
        </div>
        <button class="btn btn-primary manage m-5" (click)="nextScreen()">Submit</button>
      </div>
      <div *ngIf="showAddressChoiceScreen && !showAddressProof">
        <h5>{{headerText}}</h5>
        <div class="m-vmargin" style="margin-top: 1rem;">
          <img class="img-fluid mx-auto d-block" [src]="demoImagePath" alt="Demo image front" *ngIf="showDemoImage">
          <app-image-upload (uploadAndContinue)="onSubmitClicked($event)" [buttonText]="buttonText" 
          [descText]="instructionText" (fileSelected)="onFileSelected($event)"
          (fileRemoved)="onFileRemoved($event)" [imagePresentSrc]="oldimageBase64" [actionName] = "actionForThisPage"></app-image-upload>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4 app-body" style="overflow: hidden;" *ngIf="!currentPage"><router-outlet></router-outlet></div>
