import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CoreService } from './core.service';


@Injectable()
export class HttpService {

  constructor(private http: HttpClient, private coreService: CoreService) { }

  private getRequestOptions(): any {
    return {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  }

  public handleError = (error: any): void => {
    if (error.status === 401) {
    }
  }

  public get = (url: string): Observable<any> => {
    return from(new Promise((resolve, reject) => {
      const observable = this.http.get(url, this.getRequestOptions());

      return observable
        .toPromise()
        .then(data => {
          resolve(data);
        })
        .catch(error => {
          this.handleError(error);
          reject(error);
        });
    }));
  }

  public patch(url: string, data: any): Observable<any> {
    return from(new Promise((resolve, reject) => {
      return this.http.patch(url, data, this.getRequestOptions())
        .toPromise()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          this.handleError(error);
          reject(error);
        });
    }));
  }

  public post(url: string, data: any): Observable<any> {
    return from(new Promise((resolve, reject) => {
      return this.http.post(url, data, this.getRequestOptions())
        .toPromise()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          this.handleError(error);
          reject(error);
        });
    }));
  }
}
