import { Component, EventEmitter, Input, Output, OnChanges, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../store/app.states';
import { Observable } from 'rxjs';
import * as fromApp from '../store/app.states';
import { Loader } from '../models/utils';

@Component({
  selector: 'app-button-footer',
  template: `
  <div *ngIf="!showSubButton" style="height: 4rem;"></div>
  <div class="fixed-bottom" style="bottom: 2rem; z-index: 0;">
    <div class="row">
      <div class="col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4 fixed-content">
        <div class="grey-box p-0" style="margin: 0rem 0.2rem;" *ngIf="!(loading$ | async).loading">
          <div class="text-center px-3 py-2">
            <div *ngIf="showSubButton">
              <div class="mt-3">
                <button class="small-text button full-width-button upload-button" mat-raised-button color="accent"
                (click)="onButtonClicked($event)"
                [ngClass]="buttonColorClass">
                  {{buttonText}}
                </button>
              </div><br>
              <div class="text-center">
                <a class="resend-link my-3" (click)="onSubButtonClicked($event)" style="font-weight:bold;cursor:pointer">Take another photo
                  <img src="/assets/images/right-arrow.png" alt="img" class="ml-1">
                </a>
              </div><br>
            </div>
            <div *ngIf="!showSubButton" class=" mb-5">
              <button class="small-text button full-width-button upload-button" mat-raised-button color="accent"
              (click)="onButtonClicked($event)"
              [ngClass]="buttonColorClass">
              {{buttonText}}
            </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  `,
  styles: [
    `
    .blueButton {
      background-color: #e7387a !important;
    }
    .upload-button{
      background: #e7387a !important;
      border-radius: 15px;
      width: 100%;
      padding: 1rem 0;
      text-align: center;
      font-family: MuseoRegular900;
      font-style: normal;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      color: #FFFFFF;
    }
    `
  ]
})
export class ButtonFooterComponent implements OnChanges, OnDestroy {
  // Observables
  loading$: Observable<Loader>;
  public buttonColorClass: string;
  public isActive: boolean;

  constructor(private store: Store<AppState>) {
    this.loading$ = this.store.pipe(select(fromApp.getLoader));
    this.buttonColorClass = '';
    this.isActive = true;
  }

  @Output() buttonClicked = new EventEmitter();
  @Output() subButtonClicked = new EventEmitter();
  @Output() resetImage = new EventEmitter();

  @Input() buttonText: string;
  @Input() subButtonText: string;

  @Input() showSubButton = false;

  onButtonClicked = (): void => {
    this.buttonClicked.emit();
  }

  onSubButtonClicked = (): void => {
    this.resetImage.emit();
    this.subButtonClicked.emit();
  }

  ngOnChanges = (): void => {
    this.buttonColorClass = 'blueButton';
  }

  ngOnDestroy = (): void => {
    this.isActive = false;
  }
}
