import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewChecked, ElementRef, ViewChild, AfterViewInit,EventEmitter, Output } from '@angular/core';
import { select,Store } from '@ngrx/store';
// import { UpdateKycImageRequest } from '../../../models/profile';
import {Subject, Observable} from 'rxjs';
// import { UpdateKycImage } from '../../../store/actions/profile.action';
// import { MixpanelService } from '../../../services/mixpanel.service';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/store/app.states';
import {WebcamComponent, WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';
import { FetchProfile, UpdateKycImage } from 'src/app/store/actions/reupload.action';
import { CurrentActions } from 'src/app/constants';
import { ReuploadResponse } from 'src/app/models/reupload';
import * as appState from '../../store/app.states';
import { Router } from '@angular/router';


@Component({
    selector: 'app-shop-photo',
    templateUrl: './shop-photo.component.html',
    styleUrls: ['./shop-photo.component.css']
  })
  

export class ShopPhoto implements OnInit,OnDestroy{

  // @ViewChild('video') video: ElementRef<HTMLVideoElement>;
  // @ViewChild('webcam') webcam: any;
  // @ViewChild('webcamComponent') webcamComponent: WebcamComponent;
  // @ViewChild(WebcamComponent, { static: false }) webcamComponent: WebcamComponent;
  @ViewChild('video', { static: false }) video: ElementRef;

  @Output() backClicked: EventEmitter<any> = new EventEmitter();


  // @ViewChild('webcamComponent') webcamComponent: ElementRef;
  public actionForThisPage: string;
  private currentStateSubscription: Subscription;
  public reupload$: Observable<ReuploadResponse>;

  perform_validation: boolean = false;
  oldimageBase64: any;
  public clearSelection = false;
  public showDemoImage = true;
  public buttonText = 'Upload Image';
  instructionText:any
  public mobile: string;
  cameraActive=true
  videoStream: MediaStream;
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  public webcamErrors: WebcamInitError[] = [];
  public showWebcam: boolean=false
  videoOn:boolean=false
  public multipleWebcamsAvailable = false;
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>(); // switch between available webcams


  constructor(private store: Store<AppState>,
    private router: Router,
    private cdr: ChangeDetectorRef
    // private mixpanelService: MixpanelService
    ){
    this.reupload$ = this.store.pipe(select(appState.getProfile));
    const params: any = window.location.pathname.split('/');
    this.mobile = params[1];
    // this.store.dispatch(new FetchProfile({mobile: this.mobile}));
    
  }

    ngOnInit(): void {

      // this.startVideoStream()
      this.currentStateSubscription = this.reupload$.subscribe((data:any) => {
        if (data) { 
          if(data.current_action !==CurrentActions.SHOP_PHOTO){
            if(data.current_action == CurrentActions.SHOP_VIDEO){
              this.router.navigate([`${this.mobile}/${CurrentActions.SHOP_VIDEO}`]);
            }
            else{
                this.router.navigate([this.mobile])
            }
          }
        else { 
            // this.updateWebcam(true)
            navigator.permissions.query({ name: 'camera' as PermissionName}).then((permissionStatus) => {
              if (permissionStatus.state === 'granted') {
                // this.startVideoStream();
            this.updateWebcam(true)

              } else if (permissionStatus.state === 'prompt') {
             
                navigator.mediaDevices
                  .getUserMedia({ video: { facingMode: 'environment' } })
                  .then(() => {
                    // this.startVideoStream();
            this.updateWebcam(true)

                  })
                  .catch((error) => {
                    console.error('Error accessing webcam:', error);
                  });
              } else {
                console.error('Camera permission is denied.');
              }
            });
          }
        }})
    }

    ngOnDestroy(): void {
      if (this.videoStream) {
        this.videoStream.getTracks().forEach(track => track.stop()); // Stop all tracks in the stream
      }
    }


    public handleInitError(error: WebcamInitError): void {
      this.webcamErrors.push(error);
    }

    public showNextWebcam(directionOrDeviceId: boolean | string): void {
      this.nextWebcam.next(directionOrDeviceId);
    }

    public get nextWebcamObservable(): Observable<boolean | string> {
      return this.nextWebcam.asObservable();
    }

    startVideoStream(){
      this.showWebcam=true
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ video: true })
          .then(stream => {
            this.video.nativeElement.srcObject = stream; 
            this.video.nativeElement.play(); 
            this.videoStream = stream;
            if(stream){
              setTimeout(()=>
              this.videoOn=true
              ,2000)
            }
          })
          .catch(error => {
            console.error('Error accessing webcam:', error);
          });
      }
    }

  triggerSnapshot(): void {
    if (this.videoStream) {
      this.videoStream.getTracks().forEach(track => track.stop()); // Stop all tracks in the stream
    }
   this.trigger.next();
  }
  handleImage(webcamImage: WebcamImage): void {
   console.info('Saved webcam image', webcamImage);
   this.webcamImage = webcamImage;
   this.updateWebcam(!this.webcamImage)
  }
   
  public get triggerObservable(): Observable<void> {
   return this.trigger.asObservable();
  }

    onSubmitClicked($event) {
      this.perform_validation = false;
      const imageBase64  = (this.webcamImage  as any)._imageAsDataUrl.split(',')[1];
      if (!imageBase64) {
        return;
      }
       const updateKycImage:any = {
        base64_shop_photo:imageBase64
      };
      const payload: any = {
        mobile: this.mobile,
        document: updateKycImage
      };
        this.store.dispatch(new UpdateKycImage(payload));

    }


    perform_validation1($event: any) {
      this.perform_validation = false;
    }
    public onFileRemoved($event) {
      this.showDemoImage = true;
    }
    
    public onFileSelected($event) {
      if ($event) {
        this.showDemoImage = false;
      }
    }

    public removeImage(): void {
      this.webcamImage = null;
    }

    updateWebcam(show: boolean = false) {
      this.showWebcam = show;
      if (show) {
        WebcamUtil.getAvailableVideoInputs().then((mediaDevices: MediaDeviceInfo[]) => {
          this.videoOn=true
        });
      }
    }

    takeImage($event) {
      this.startVideoStream();
      this.updateWebcam(true);
    }

    backPressed() {
      if (this.showWebcam) {
        this.updateWebcam();
      } else {
        this.backClicked.emit();
      }
    }
    
}