import { Component, OnInit, OnDestroy } from '@angular/core';
// import { UpdateToolbar, RefreshLoanAndProfileData } from '../../../store/actions/core.action';
import { select, Store } from '@ngrx/store';
// import { AppState } from '../../../store/app.states';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';

import * as appState from 'src/app/store/app.states';
// import { Loan } from '../../../models/loan';
// import { Profile } from '../../../models/profile';
// import { ApiService } from '../../../services/api.service';
// import { LocalStorageConstants } from '../../../utils/constants';
// import { SkipDigilocker } from '../../../store/actions/loan.action';
import { interval } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// import { DigiSucessComponent } from '../digi-sucess/digi-sucess.component';
// import { DigiFailComponent } from '../digi-fail/digi-fail.component';
// import { DialogService } from '../../../services/dialog.service';
import { AppState } from 'src/app/store/app.states';
import { ApiService } from 'src/app/services/api.service';
import { CurrentActions, LocalStorageConstants } from 'src/app/constants';
import { ReuploadResponse } from 'src/app/models/reupload';
import { DigiWaitingComponent } from '../digi-waiting/digi-waiting.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-digilocker-consent',
  templateUrl: './digilocker-consent.component.html',
  styleUrls: ['./digilocker-consent.component.css']
})
export class DigilockerConsent implements OnInit, OnDestroy {
  digiLink$: Observable<any>;
  // loan$: Observable<Loan>;
  mobile: string;
  public reupload$: Observable<ReuploadResponse>;


  // Subscriptions
  soaPdfSubscription: Subscription;
  loanSubscription: Subscription;
  loanUid: string;
  transaction_id: string;
  showErrorMsg: string;
  digilockerWindow;
  intervalId: any = null
  takeAadharOtpInput: boolean = false;
  private currentStateSubscription: Subscription;


  partnerSlug: string = localStorage.getItem(LocalStorageConstants.PARTNER_SLUG_KEY);
  private timeSubscription: Subscription;
  // timer_id: NodeJS.Timer;
  tid:any=null
  dialogRef:any

  constructor(private store: Store<AppState>, private apiService: ApiService, public dialog: MatDialog,
    private router: Router
    // private dialogService: DialogService,
    ) {
    // this.loan$ = this.store.pipe(select(appState.getLoanDetails));
    this.reupload$ = this.store.pipe(select(appState.getProfile));

  }

  ngOnInit() {
    const params: any = window.location.pathname.split('/');
    this.mobile = params[1];
    // this.loanSubscription = this.loan$.subscribe(loanDetails => {
    //   if (loanDetails) {
    //     this.loanUid = loanDetails.loan_uid;
    //   }
    // });
    // this.store.dispatch(new UpdateToolbar({ visible: true, text: null, showBack: false }));
    this.currentStateSubscription = this.reupload$.subscribe((data:any) => {
      if (data) { 
        this.loanUid=data?.loan_uid
        this.partnerSlug=data?.partner_code
        if(data.current_action !==CurrentActions.DIGILOCKER_CONSENT){
          this.router.navigate([this.mobile]);
        }

      }})
     this.tid = localStorage.getItem('Tid');
    if (this.tid) {
      this.transaction_id = this.tid;
      this.dialogRef = this.dialog.open(DigiWaitingComponent);
      this.errorTimerAction();
      this.intervalId = setInterval(() => {
        // this.store.dispatch(new RefreshLoanAndProfileData());
        this.errorTimerAction()
      }, 8000);
    }
    // this.store.dispatch(new RefreshLoanAndProfileData());

  }

  ngOnDestroy() {
    if (this.loanSubscription) {
      this.loanSubscription.unsubscribe();
    }
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
    localStorage.removeItem('Tid');
    if(this.dialogRef){
    this.dialogRef.close();
    }
  }

  digilockerFlow() {
    this.apiService.fetchInvoidUrl(this.loanUid, this.partnerSlug).subscribe(response => {
      console.log(response.transaction_id, 'transaction_id');
      this.transaction_id = response.transaction_id;
      localStorage.setItem('Tid', this.transaction_id);
      // this.showIframe = true;
      if (response.url === '' || response.url === null) {
        this.showErrorMsg = 'Invalid URL';
        // this.errorTimerAction();
      } else {
        this.showErrorMsg = '';
        console.log(response.url, 'response.url');
        // window.open( response.url, '_self');
        this.digilockerWindow = window.open(response.url, '_self');
        // console.log(this.digilockerWindow, 'digilockerWindow');
        this.errorTimerAction();
      }

    },
    error => {
      this.showErrorMsg = error.error.error;
      // this.errorTimerAction();
      console.log(error);
    });
  }

  errorTimerAction() {
      this.apiService.digilockerDetails(this.transaction_id).subscribe(response => {
      if (response.status === 'fail') {
        console.log('fail');
        // this.dialogService.showErrorDialog(true,'Digilocker Verification Failed');
        this.tid=false
        localStorage.removeItem('Tid');
      }
      if (response.status === 'waiting' && !this.tid) {
        this.dialogRef = this.dialog.open(DigiWaitingComponent);
      }
      });
      // this.sdkFlow_2();
    // }.bind(this), 120000);
  }

  closeTab = () => {
    this.digilockerWindow.close();
  }

  // sdkFlow() {
  //   console.log('sdkFlow time out called');
  //   this.store.dispatch(new SkipDigilocker(this.loanUid));
  //   window.location.href = `${this.partnerSlug}/session/${this.mobile}/upload_pan`;
  // }
  // sdkFlow_2() {
  //   console.log('sdkFlow time out called');
  //   const source = interval(5000);
  //      this.timeSubscription = source.subscribe(val => {
  //       this.apiService.digilockerDetails(this.transaction_id).subscribe(response => {
  //         if (response.status === 'success') {
  //           clearTimeout(this.timer_id);
  //           this.timeSubscription.unsubscribe();
  //           this.digilockerWindow.close();
  //           const dialogRef = this.dialog.open(DigiSucessComponent);
  //           window.location.reload();
  //           // window.location.href = `${this.partnerSlug}/session/${this.mobile}/digilocker_consent/sucess`;
  //         }
  //         if (response.status === 'waiting') {
  //           console.log('waiting');
  //           const dialogRef = this.dialog.open(DigiWaitingComponent);
  //           // window.location.href = `${this.partnerSlug}/session/${this.mobile}/digilocker_consent/waiting`;
  //         }
  //         if (response.status === 'fail') {
  //           console.log('fail');
  //           const dialogRef = this.dialog.open(DigiFailComponent);
  //           // window.location.href = `${this.partnerSlug}/session/${this.mobile}/digilocker_consent/fail`;
  //         }
  //       });
  //     });
  //   this.timer_id  = setTimeout(() => {
  //     console.log('2 min timer');
  //     this.digilockerWindow.close();
  //     this.sdkFlow();
  //     this.timeSubscription.unsubscribe();
  //   }, 2 * 60 * 1000);
  // }
}
