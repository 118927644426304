import { Component, OnInit, NgZone, Output, EventEmitter, Input, OnChanges, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { PathConstants, Constants, CurrentActions, LocalStorageConstants } from '../../constants';

class ImageSnippet {
  constructor(public src: string, public file: File) {}
}

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.css']
})
export class ImageUploadComponent implements OnChanges {

  public imageString: string;
  public selectedFile: ImageSnippet;
  public buttonColorClass = '';
  public imageSizeError: string;
  public _imagePresentSrc: string;
  @Input() imageType = '';
  @Input() photoType = '';
  @Input() actionName = '';
  @Input() resetImg = false;
  @Input() imagePresent = false;
  @Input() buttonText = 'Upload Image';
  @Input() descText = '';

  @Output() uploadAndContinue = new EventEmitter<any>();
  @Output() fileSelected = new EventEmitter<string>();
  @Output() fileRemoved = new EventEmitter<void>();

  @Input('imagePresentSrc')
  set imagePresentSrc(value: string) {
    if (value) {
      setTimeout(() => {
        this.selectedFile = new ImageSnippet(this._imagePresentSrc, null);
        this.fileSelected.emit(this.selectedFile.src);
        }, 200);
    }
    this._imagePresentSrc = value;
  }

  get imagePresentSrc(): string { return this._imagePresentSrc; }

  constructor() {
  }

  ngOnChanges(): void {
    this.buttonColorClass = 'blueButton';
    this.removeImage();
  }

  public processFile(imageInput: any): void {
    const file: File = imageInput.target.files[0];
    const reader = new FileReader();

    if (this.validateImageSize(file)) {
      reader.addEventListener('load', (event: any) => {
        this.selectedFile = new ImageSnippet(event.target.result, file);
        this.fileSelected.emit(this.selectedFile.src);
      });

      reader.readAsDataURL(file);
    }
  }

  public removeImage(): void {
    this.selectedFile = null;
    this.fileRemoved.emit();
    // this.store.dispatch(new ResetErrorText());
  }

  public resetImage(): void {
  }

  public continue(): void {
    this.uploadAndContinue.emit(this.selectedFile);
  }

  public validateImageSize(file): boolean {
    if ((file.size / 1024) > Constants.IMAGE_MAX_SIZE_IN_KB) {
      this.imageSizeError = 'File size should be less than 4 MB';
      return false;
    }
    this.imageSizeError = null;
    return true;
  }

}
