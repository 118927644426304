import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-loader',
  template: `
    <div style="text-align: center;">
    <img src="/assets/images/loader-bg.gif" alt="HTML5 Icon" style="width:100%;height:100%;">
    </div>
  `,
  styles: [`
    .spinner-text {
      margin: 10px;
      text-align: center;
    }
    .spinner {
      margin: auto;
    }
    ::ng-deep .mat-dialog-container {
      background: none;
      box-shadow: none;
    }
    ::ng-deep .mat-dialog-container {
      padding: 0px;
    }
  `],
})
export class LoaderComponent {
  constructor(public dialogRef: MatDialogRef<LoaderComponent>,
              @Inject(MAT_DIALOG_DATA) public data: string) {}
}
