import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpService } from './http.service';
import { Reupload } from '../models/reupload';
import { ApiConstants} from '../constants';

@Injectable()
export class ApiService {
  private REUPLOAD_URL = environment.reuploadUrl;
  private SDK_API_URL = environment.sdkApiUrl;
  partnerSlug:any
  constructor(private http: HttpService) {
    this.partnerSlug=localStorage.getItem('partnerSlug')
  }

  fetchReuploadProfile(mobile: string): Observable<any> {
    return this.http.get(`${this.REUPLOAD_URL}${ApiConstants.API_GET_KYC_STATUS}${mobile}`);
  }

  updateDocument(mobile: string, reupload: Reupload): Observable<any> {
    return this.http.patch(`${this.REUPLOAD_URL}${ApiConstants.API_UPDATE_KYC}${mobile}`, { reupload });
  }

  fetchInvoidUrl(loanUid: string, partnerSlug: string): Observable<any> {
    return this.http.get(
      `${this.REUPLOAD_URL}${ApiConstants.API_INVOID_URL}${loanUid}`
    );
  }

  digilockerDetails(transaction_id: string): Observable<any> {
    return this.http.get(
      `${this.SDK_API_URL}${this.partnerSlug}/show_transaction_status/${transaction_id}`
    );
  }
}
