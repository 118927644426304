import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../store/app.states';
import { Loader } from '../models/utils';
import { LoaderComponent } from '../components/loader.component';
import * as fromApp from '../store/app.states';
import { Subscription } from 'rxjs';

@Injectable()
export class LoaderService {
  // Observables
  loader$: Observable<Loader>;

  // Subscriptions
  loaderSubscription: Subscription;

  // Local variables
  dialogRef: MatDialogRef<LoaderComponent>;

  constructor(private store: Store<AppState>, public dialog: MatDialog) {
    this.loader$ = this.store.pipe(select(fromApp.getLoader));
  }

  public init(): void {
    this.loaderSubscription = this.loader$.subscribe((loader) => {
      if (loader != null) {
        if (loader.loading) {
          this.showLoader(loader.loaderText);
        } else {
          this.hideLoader();
        }
      }
    });
  }

  public destroy(): void {
    if (this.loaderSubscription) {
      this.loaderSubscription.unsubscribe();
    }
  }

  showLoader(loaderText: string = 'Loading...'): void {
    this.dialogRef = this.dialog.open(LoaderComponent, {
      width: '250px',
      data: loaderText
    });
  }

  hideLoader(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
