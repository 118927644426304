<div class="app-body col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 mt-5">
  <div class="box-container m-vmargin">
    <div class="box" style="margin-bottom: 40px !important;">
      <div class="inner-box">
    <h1 class="box-title">Dear Customer,</h1>
        <h2>Register with Aadhaar Number in 2 simple steps</h2>
        <div class="box-detail">
          <div class="box-img">
            <img src="/assets/images/aadhar.jpg" alt="aadhar card" />
          </div>
          <div class="box-text">
            <ul>
              <li>1.Enter your Aadhar No.</li>
              <li>2.Enter OTP/PIN verification (received on your Aadhar linked mobile number)</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- <button class=" btn btn-primary small-btn" type="submit" (click)="digilockerFlow()" > Continue </button> -->

    </div>
<!-- 
    <div class="box" style="margin-bottom: 6rem!important;">
      <div class="inner-box">
        <h2>Register with PAN Card & Driving License/Voter ID Card</h2>

        <div class="box-detail">
          <div class="box-img">
            <img src="/assets/images/new_page/pan.png" alt="pan card" />
          </div>
          <div class="box-text">
            <ul>
              <li>1.Upload PAN Card image.</li>
              <li>2.Uploading Driving Licence or voter ID card 
                (Front & Back)
              </li>
            </ul>
          </div>
        </div>
      </div>
      <button class=" btn btn-primary small-btn" type="submit" (click)="sdkFlow()"> Continue </button>
    </div> -->

  <!-- </div> -->
  </div>
  <!-- <app-button-footer  (buttonClicked)="digilockerFlow()"
  [buttonText]="'Continue'"></app-button-footer> -->
  <app-button-footer  (buttonClicked)="digilockerFlow()" [buttonText]="'Continue'"></app-button-footer>
  <!-- <app-button-footer *ngIf="takeAadharOtpInput"  (buttonClicked)="submitSurepassOtp()" [buttonText]="'Submit OTP'"></app-button-footer> -->
</div>
