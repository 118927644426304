<div *ngIf="selectedFile && selectedFile.src" >
  <div class="img-preview text-center">
    <img [src]="selectedFile.src" class="img-fluid mx-auto d-block" />
  </div>
  <app-button-footer (buttonClicked)="continue()" [buttonText]="'CONFIRM'" (subButtonClicked)="removeImage()" (resetImage)="resetImage()"
  [subButtonText]="'Remove'" [showSubButton]="true"></app-button-footer>
</div>

<div *ngIf="(!selectedFile || !selectedFile.src)" class="fixed-bottom">
  <div class="text-center mb-2" *ngIf="imageSizeError">
    <mat-error class="form-common-error">
      {{imageSizeError}}
    </mat-error>  
  </div>
  <!-- <h5>Reject reason : {{descText}}</h5><br> -->
  <label class="image-upload-container btn btn-bwm mat-raised-button upload-label" [ngClass]="buttonColorClass">
    <span>{{ buttonText }}</span>
    <input #imageInput type="file" accept="image/*" id="fileInp" capture="camera" data-max-size="4096" (change)="processFile($event)">
  </label>
</div>
