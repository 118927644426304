<div class="fixed-top">
    <div class="row">
      <div class="col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4 fixed-content">
        <mat-toolbar class="toolbar p-0" style="background: #FFF;">
          <div class="toolbar-text-div">
            <img class="toolbar-logo" src="/assets/images/arth-logo.png">
          </div>
        </mat-toolbar>
        <mat-toolbar class="toolbar p-0" style="background: #FFF;">
          <div class="toolbar-text-div">Action for Re KYC</div>
        </mat-toolbar>
      </div>
    </div>
  </div>
  <div style="height: 7rem;"></div>