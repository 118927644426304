import { Action } from '@ngrx/store';
import { Reupload, FetchReuploadProfileResponse, FetchReuploadProfile } from '../../models/reupload';

export enum ReuploadActionTypes {
  FetchProfile = '[Reupload] Fetch Reupload Profile',
  FetchProfileSuccess = '[Reupload] Fetch Reupload Profile Success',
  FetchProfileFailure = '[Reupload] Fetch Reupload Profile Failure',
  UpdateKycImage = '[Reupload] Update Kyc Image',
  UpdateKycImageSuccess = '[Reupload] Update Kyc Image Success',
  UpdateKycImageFailure = '[Reupload] Update Kyc Image Failure'
}

export class FetchProfile implements Action {
  readonly type = ReuploadActionTypes.FetchProfile;
  constructor(public payload: FetchReuploadProfile) { }
}

export class FetchProfileSuccess implements Action {
  readonly type = ReuploadActionTypes.FetchProfileSuccess;

  constructor(public payload: FetchReuploadProfileResponse) {}
}

export class FetchProfileFailure implements Action {
  readonly type = ReuploadActionTypes.FetchProfileFailure;

  constructor(public payload: any) {}
}

export class UpdateKycImage implements Action {
  readonly type = ReuploadActionTypes.UpdateKycImage;

  constructor(public payload: any) {}
}

export class UpdateKycImageSuccess implements Action {
  readonly type = ReuploadActionTypes.UpdateKycImageSuccess;

  constructor(public payload: FetchReuploadProfileResponse) {}
}

export class UpdateKycImageFailure implements Action {
  readonly type = ReuploadActionTypes.UpdateKycImageFailure;

  constructor(public payload: any) {}
}

export type ReuploadActionUnions =
  | UpdateKycImage
  | UpdateKycImageSuccess
  | UpdateKycImageFailure
  | FetchProfile
  | FetchProfileSuccess
  | FetchProfileFailure;
