<div style="height: 4.5rem;"></div>
<div class="fixed-bottom">
  <div class="row">
    <div class="col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4 fixed-content" style="background: white;">
      <div class="px-4 py-2 text-center">
        <span class="small-text">
          <h5 style="font-size: medium;
          font-weight: inherit; color: #3c33ac;">Write to us at <a href="mailto:info@arthdigital.net ">info@arthdigital.net 
          </a> for any queries.</h5> 
        </span>
      </div>
    </div>
  </div>
</div>