<div class="col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4 app-body">
  <div class="box" style="margin-bottom: 6rem!important;">
    <div>
      <br/><br/>
      <div class="banner box">
        <div class="box-title mb-4">
          <h4>{{headerText}}</h4>
        </div>
        <img class="img-fluid mx-auto d-block w-50" [src]="imagePath" alt="select-number-banner">
      </div>
      <div class="check-status">
        <div class="box-title mt-5 mb-5">
          <h4>{{descText}}</h4>
        </div>
      </div>  
    </div>
  </div>
</div>
<div class="app-body">
  <section class="main">
  </section>
</div>