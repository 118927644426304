import { Component, OnInit } from '@angular/core';
// import { LocalStorageConstants } from '../../../utils/constants';

@Component({
  selector: 'app-digi-waiting',
  templateUrl: './digi-waiting.component.html',
  styleUrls: ['./digi-waiting.component.css']
})
export class DigiWaitingComponent implements OnInit {
  mobile: string;
  partnerSlug: string;
  constructor() { }

  ngOnInit() {
    // this.mobile = localStorage.getItem(LocalStorageConstants.MOBILE_KEY);
    // this.partnerSlug = localStorage.getItem(LocalStorageConstants.PARTNER_SLUG_KEY);
    // setTimeout(() => {
    //   window.location.href = `${this.partnerSlug}/session/${this.mobile}/digilocker_consent`;
    // }, 12000);
  }
}
