import { ErrorDialog } from '../../models/utils';
import { ReuploadActionUnions, ReuploadActionTypes } from '../actions/reupload.action';
import { ErrorDialogActionTypes, ErrorDialogActionsUnion } from '../actions/error-dialog.action';

export interface State {
  errorDialog: ErrorDialog | null;
}

export const initialState: State = {
  errorDialog: {
    showDialog: false,
    errorText: 'Something went wrong'
  }
};

export function reducer(state = initialState,
                        action: ReuploadActionUnions | ErrorDialogActionsUnion ): State {
  switch (action.type) {
    case ReuploadActionTypes.FetchProfileFailure:
    case ReuploadActionTypes.UpdateKycImageFailure: {
      if (state.errorDialog.showDialog) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        errorDialog: {
          showDialog: true,
          errorText: action.payload.error.error || 'Something went wrong'
        },
      };
    }
    case ErrorDialogActionTypes.CloseErrorDialog:
      return {
        ...state,
        errorDialog: {
          showDialog: false,
          errorText: null
        },
      };
    default : {
      return state;
    }
  }
}

export const getErrorDialog = (state: State) => state.errorDialog;
