import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { ROUTES } from './app.routes';
import { StoreModule } from '@ngrx/store';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material';
import { AppComponent } from './app.component';
import { TopToolbarComponent } from './components/top-toolbar/top-toolbar.component';
import { AppFooterComponent } from './components/app-footer/app-footer.component';
import { HttpClientModule } from '@angular/common/http';
import { ReuploadPhotoComponent } from './views/reupload-photo/reupload-photo.component';
import { ReuploadEffects } from './store/effects/reupload.effect';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { NotFoundPageComponent } from './views/not-found-page/not-found-page.component';
import { LoaderComponent } from './components/loader.component';
import { LoaderService } from './services/loader.service';
import { CoreService } from './services/core.service';
import { reducers } from './store/app.states';
import { ButtonFooterComponent } from './components/button-footer.component';
import { ApiService } from './services/api.service';
import { HttpService } from './services/http.service';
import { RedirectEffects } from './store/effects/redirect.effect';
import { WaitWithoutActionComponent } from './views/wait-without-action/wait-without-action.component';
import { CoreEffects } from './store/effects/core.effect';
import { ErrorDialogService } from './services/error-dialog.service';
import { ErrorDialogComponent } from './components/error-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ShopPhoto } from './views/shop-photo/shop-photo.component';
import { WebcamModule } from 'ngx-webcam';
import { ShopEnvironmentVideo } from './views/shop-environment-video/shop-environment-video.component';
import { DigilockerConsent } from './views/digilocker/digilocker-consent.component';
import { DigiWaitingComponent } from './views/digi-waiting/digi-waiting.component';

@NgModule({
  declarations: [
    AppComponent,
    TopToolbarComponent,
    AppFooterComponent,
    ReuploadPhotoComponent,
    LoaderComponent,
    ImageUploadComponent,
    ButtonFooterComponent,
    NotFoundPageComponent,
    ErrorDialogComponent,
    WaitWithoutActionComponent,
    ShopPhoto,
    ShopEnvironmentVideo,
    DigilockerConsent,
    DigiWaitingComponent
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    MatDialogModule,
    BrowserAnimationsModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, {}),
    RouterModule.forRoot(ROUTES, { onSameUrlNavigation: 'reload'}),
    EffectsModule.forRoot([
      CoreEffects,
      ReuploadEffects,
      RedirectEffects,
    ]),
    WebcamModule
  ],
  providers: [LoaderService, CoreService, ApiService, HttpService, ErrorDialogService],
  bootstrap: [AppComponent],
  entryComponents: [LoaderComponent, ErrorDialogComponent]
})
export class AppModule { }
