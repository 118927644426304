<div class="mt-3">
  <div class="box mb-3" style="background-color: #ffff;">
    <div class="inner-box text-center pt-3">
      <h4>Upload Shop Environment Video</h4>
    </div>
  
  <div class="text-center">
    <video #video class="video-container" autoplay></video>
  </div>
</div>
<div class="box-title text-center mb-5" >
  <div class="text-center">
    <div class="mt-3" style="color: #EB1B69;">
   ({{recordingDuration}} seconds)
    </div>
    <div class="d-flex text-center justify-content-center">
    <button class=" btn btn-primary m-2" style="background-color: #EB1B69;" (click)="startRecording()" [disabled]="!videoStream || mediaRecorder?.state.includes('recording')">Start
    </button>
    <button class=" btn btn-primary m-2" style="background-color: #EB1B69;" (click)="stopRecording()" [disabled]="!mediaRecorder?.state.includes('recording')">Stop</button>
  </div>
    
    <app-button-footer class="mt-2" *ngIf="showNext && videoOn" (buttonClicked)="sendVideo()"  [buttonText]="'NEXT'"></app-button-footer>

    <!-- <button (click)="downloadVideo()" [disabled]="!recordedVideoUrl">Download Video</button> -->
  </div>
  </div>
  
</div>