<div class="box-container">
  <div class="box1">
      <div class="inner-box-in">
          <!-- <h2>Complete your KYC in 2 simple steps with Aadhar Number</h2> -->
          <h1>Please wait</h1>
          <h2>Your information is validating.</h2>
          <div class="box-detail">
          </div>
      </div>
  </div>
</div>