import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators/';
import { Router, ActivatedRoute } from '@angular/router';

import { NotFoundPage, ErrorPage, RedirectActionTypes } from '../actions/redirect.action';

@Injectable()
export class RedirectEffects {

  constructor(
    private actions: Actions,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  @Effect({ dispatch: false })
  NotFoundPage = this.actions.pipe(
    ofType<NotFoundPage>(RedirectActionTypes.NotFoundPage),
    switchMap(() => this.router.navigate(['404'])
  ));

}
