import { Component, OnInit, NgModule, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';


import { Reupload, ReuploadResponse } from '../../models/reupload';
import { AppState } from '../../store/app.states';
import * as appState from '../../store/app.states';
import { UpdateKycImage } from '../../store/actions/reupload.action';
import { FetchProfile } from '../../store/actions/reupload.action';
import { Router } from '@angular/router';
import { CurrentActions } from 'src/app/constants';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-reupload-photo',
  templateUrl: './reupload-photo.component.html',
  styleUrls: ['./reupload-photo.component.css']
})

export class ReuploadPhotoComponent implements OnInit, OnDestroy {

  // Observables
  public errorText$: Observable<string>;
  public reupload$: Observable<ReuploadResponse>;

  // Subscriptions
  private currentStateSubscription: Subscription;

  // Component variables
  public demoImagePath: string;
  public headerText: string;
  public instructionText: string;
  public showWebcam: boolean;
  public showImageToolbarTitle: string;
  public captureImageToolbarTitle: string;
  public buttonText: string;
  public showDemoImage: boolean;
  public actionForThisPage: string;
  public photoRejectReason: string;
  public poiRejectReason: string;
  public poaFrontRejectReason: string;
  public poaBackRejectReason: string;
  public showChoiceScreen: boolean = false;
  public showAddressChoiceScreen: boolean = false;
  public showAddressProof: boolean;
  public currentPage: boolean=false;

  options = [
    { name: "Select Address Type", value: '' },
    // { name: "Driving License", value: 'driving_license' },
    // { name: "Voter Id", value: 'voter_id' },
    { name: "Aadhar Card", value: 'aadhaar' },
  ]

  public confInstructionText: string;
  public mobile: string;
  public loanUid: string;
  digilockerWindow;
  public partnerSlug: string;
  oldimageBase64: any;
  // apiService: any;
  addressProofType: any;
  constructor(private store: Store<AppState>,
              private apiService: ApiService,
              private router: Router,
              private cdref: ChangeDetectorRef){
    this.errorText$ = this.store.pipe(select(appState.getErrorText));
    this.reupload$ = this.store.pipe(select(appState.getProfile));

  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  public onFileSelected($event): void {
    if ($event) {
      this.showDemoImage = false;
    }
  }

  public onFileRemoved($event): void {
    this.showDemoImage = true;
  }

  ngOnInit(): void {
    this.addressProofType = localStorage.getItem('addressType');
    const params: any = window.location.pathname.split('/');
    this.mobile = params[1];
    this.store.dispatch(new FetchProfile({mobile: this.mobile}));
    this.currentStateSubscription = this.reupload$.subscribe((data:any) => {
      if (data) { 
        if(data.current_action ===CurrentActions.REUPLOAD_ADDRESS_PROOF_BACK || data.current_action ===CurrentActions.REUPLOAD_ADDRESS_PROOF_FRONT || data.current_action ===CurrentActions.REUPLOAD_PROFILE_IMAGE || data.current_action ===CurrentActions.REUPLOAD_PAN){
        this.currentPage=true
        this.router.navigate([this.mobile]);
        }
        if(data.current_action ===CurrentActions.SHOP_PHOTO || data.current_action ===CurrentActions.SHOP_VIDEO){
          this.currentPage=false
          this.router.navigate([`${this.mobile}/${data.current_action}`]);
          return
          }
        if(data.requested_docs.includes('poa_front')) {
          if(data.requested_docs.includes('poa_back') && !data.reupload_photo && !data.reupload_pan && (!data.reupload_address_proof_back || !data.reupload_address_proof_front)) {
            this.showAddressChoiceScreen = true
          } else {
            localStorage.setItem('addressType', data?.address_proof_type);
          }
        } else {
          localStorage.setItem('addressType', data?.address_proof_type);
        }
        if (data.current_action === CurrentActions.THANK_YOU) {
          this.router.navigate([CurrentActions.THANK_YOU]);
          localStorage.clear();
          return true;
        }
        if (this.actionForThisPage && this.actionForThisPage !== data.current_action) {
          window.location.reload();
        }
        else {
          this.actionForThisPage = data.current_action;
          this.photoRejectReason = data.reupload_photo_reason;
          this.poiRejectReason = data.reupload_pan_reason;
          this.poaFrontRejectReason = data.reupload_address_proof_front_reason;
          this.poaBackRejectReason = data.reupload_address_proof_back_reaosn;
          this.loanUid = data.loan_uid;
          this.partnerSlug = data.partner_code.toLowerCase();
          localStorage.setItem('partnerSlug',data.partner_code.toLowerCase())
          this.initializeTexts();
          this.rekycFlow(this.actionForThisPage)
          // this.showChoiceScreen = (this.actionForThisPage === 'reupload_address_proof_front' || this.actionForThisPage === 'reupload_address_proof_front');
        }
        if(data.reupload_address_proof_back && data.reupload_address_proof_front && data.reupload_pan && data.reupload_photo) {
          this.showAddressProof = false
        } else if(data.reupload_address_proof_back && data.reupload_address_proof_front && data.reupload_photo) {
          this.showAddressProof = false
        } else if(data.reupload_address_proof_back && data.reupload_address_proof_front) {
          this.showAddressProof = true
        } else {
          this.showAddressProof = false
        }
      }
    });
  }

  rekycFlow(data){
    if(data == 'reupload_address_proof_front' || data == 'reupload_address_proof_back') {
      this.showChoiceScreen = true
      this.showAddressChoiceScreen = true
    } else {
      this.showAddressChoiceScreen = true;
    }
    return;
  }
  async getBase64ImageFromUrl(imageUrl): Promise<any> {
    const res = await fetch(imageUrl);
    const blob = await res.blob();

    return new Promise((resolve, reject) => {
      const reader  = new FileReader();
      reader.addEventListener('load', function(): void {
          resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }

  doStuff(result): void {
    this.oldimageBase64 = result;
  }

  public ngOnDestroy(): void {
    if (this.currentStateSubscription) {
      this.currentStateSubscription.unsubscribe();
    }
  }

  initializeTexts(): void {
    switch (this.actionForThisPage) {
      case 'reupload_photo':
        this.demoImagePath = '/assets/images/selfie-banner-image.png';
        this.headerText = 'Click your Selfie';
        this.instructionText = this.photoRejectReason || 'Capture clear image of yourself.';
        this.buttonText = 'Reupload Selfie';
        break;
      case 'reupload_pan':
        this.demoImagePath = '/assets/images/Group_9565.png';
        this.headerText = 'Reupload your Pan';
        this.instructionText = this.poiRejectReason || 'Capture clear image of your pan.';
        this.buttonText = 'Reupload Pan';
        break;
      case 'reupload_address_proof_front':
        this.showAddressProof = true;
        this.demoImagePath = '/assets/images/Group_9606.png';
        this.headerText = 'Reupload your POA Front';
        this.instructionText = this.poaFrontRejectReason || 'Capture clear image of your POA.';
        this.buttonText = 'Reupload Address Proof Front';
        break;
      case 'reupload_address_proof_back':
        this.showAddressProof = true;
        this.demoImagePath = '/assets/images/Group_9609.png';
        this.headerText = 'Reupload your POA Back';
        this.instructionText = this.poaBackRejectReason || 'Capture clear image of your POA.';
        this.buttonText = 'Reupload Address Proof Back';
        break;
      default:
        this.demoImagePath = '/assets/images/selfie-banner-image.png';
        this.headerText = 'Click your Selfie';
        this.instructionText = 'Capture clear image of yourself.';
        this.buttonText = 'Reupload Selfie';
    }
  }

  onSubmitClicked($event): void {
    const imageBase64 = $event.src.split(',')[1];
    if (!imageBase64) {
      this.initializeTexts();
      return;
    }
    const updateKycImage: Reupload = {
      base64_poi: this.actionForThisPage === 'reupload_pan' ? imageBase64 : null,
      address_proof_type: this.actionForThisPage === 'reupload_photo' ? null : localStorage.getItem('addressType'),
      base64_address_proof_front: this.actionForThisPage === 'reupload_address_proof_front' ? imageBase64 : null,
      base64_address_proof_back: this.actionForThisPage === 'reupload_address_proof_back' ? imageBase64 : null,
      base64_photo: this.actionForThisPage === 'reupload_photo' ? imageBase64 : null
    };

    const payload: any = {
      mobile: this.mobile,
      document: updateKycImage
    };
    this.store.dispatch(new UpdateKycImage(payload));
  }

  digilockerFlow() {
    this.apiService.fetchInvoidUrl(this.loanUid, this.partnerSlug).subscribe(response => {
      // this.digilockerWindow = window.open(response.url, '_blank');
      this.digilockerWindow = window.open(response.url, '_blank');
      console.log(this.digilockerWindow, 'digilockerWindow');
    });
  }

  submit(data) {
    this.addressProofType = data
    localStorage.setItem('addressType', data);
  }

  nextScreen() {
    this.showAddressChoiceScreen = true;
    this.showAddressProof = false;
    return;
  }
}
