import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { tap, exhaustMap, map, catchError, switchMap } from 'rxjs/operators';
import { Observable, from, of } from 'rxjs';
import { Router } from '@angular/router';
import { CoreActionTypes, ReadDatabase, ReadDatabaseSuccess, ReadDatabaseFailure } from '../actions/core.action';
import { CoreService } from '../../services/core.service';
import { ApiService } from '../../services/api.service';

@Injectable()
export class CoreEffects {
  constructor(
    private actions: Actions,
    private router: Router,
    private apiService: ApiService,
    private coreService: CoreService
  ) {}

  // @Effect()
  // ReadDatabase = this.actions.pipe(
  //   ofType<ReadDatabase>(CoreActionTypes.ReadDatabase),
  //   map(action => action.payload),
  //   exhaustMap(mobile => {
  //     return this.apiService.readDatabase(mobile).pipe(
  //       map(response => new ReadDatabaseSuccess(response)),
  //       catchError(error => of(new ReadDatabaseFailure(error)))
  //     );
  //   })
  // );

  // @Effect({ dispatch: false })
  // CoreSuccess = this.actions.pipe(
  //   ofType(CoreActionTypes.ReadDatabase)
  // );
}
