import { Loader } from '../../models/utils';
import { CoreActionsUnion, CoreActionTypes } from '../actions/core.action';
import { ReuploadActionUnions, ReuploadActionTypes } from '../actions/reupload.action';

export interface State {
  loader: Loader | null;
}

export const initialState: State = {
  loader: {
    loading: false,
    loaderText: 'Loading...'
  }
};

export function reducer(state = initialState,
                        action: CoreActionsUnion | ReuploadActionUnions): State {
  switch (action.type) {
    case CoreActionTypes.ReadDatabase:
    case ReuploadActionTypes.FetchProfile:
    case ReuploadActionTypes.UpdateKycImage: {
      if (state.loader.loading) {
        return {
          ...state
        };
      }
      return {
        ...state,
        loader: {
          loading: true,
          loaderText: getLoaderText(action.type)
        }
      };
    }
    case CoreActionTypes.ReadDatabaseSuccess:
    case CoreActionTypes.ReadDatabaseFailure:
    case ReuploadActionTypes.FetchProfileSuccess:
    case ReuploadActionTypes.FetchProfileFailure:
    case ReuploadActionTypes.UpdateKycImageSuccess:
    case ReuploadActionTypes.UpdateKycImageFailure: {
      if (!state.loader.loading) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        loader: {
          loading: false,
          loaderText: ''
        },
      };
    }

    default : {
      return state;
    }
  }
}

function getLoaderText(action): string {
  switch (action) {
    case CoreActionTypes.ReadDatabase:
      return 'Refreshing data...';
    case ReuploadActionTypes.FetchProfile:
      return 'Fetching data...';
    case ReuploadActionTypes.UpdateKycImage:
      return 'Uploading image...';
    default:
      return 'Please wait..';
  }
}
export const getLoader = (state: State) => state.loader;
