import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as core from './reducers/core.reducer';
import * as loader from './reducers/loader.reducer';
import * as reupload from './reducers/reupload.reducer';
import * as errorDialog from './reducers/error-dialog.reducer';

export interface AppState {
  coreState: core.State;
  reuploadState: reupload.State;
}

export const reducers = {
  core: core.reducer,
  loader: loader.reducer,
  reupload: reupload.reducer
};

export const selectCoreState = createFeatureSelector<core.State>('core');
export const getCurrentState = createSelector(selectCoreState, core.getCurrentState);

export const selectLoaderState = createFeatureSelector<loader.State>('loader');
export const getLoader = createSelector(selectLoaderState, loader.getLoader);

export const selectErrorDialogState = createFeatureSelector<errorDialog.State>('errorDialog');

export const getErrorDialog = createSelector(selectErrorDialogState, errorDialog.getErrorDialog);

export const selectReuploadState = createFeatureSelector<reupload.State>('reupload');
export const getProfile = createSelector(selectReuploadState, reupload.getProfile);
export const getErrorText = createSelector(selectReuploadState, reupload.getErrorText);
