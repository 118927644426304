import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewChecked, ViewChild, ElementRef, NgZone } from '@angular/core';
import { Store, select } from '@ngrx/store';
// import { AppState } from '../../../store/app.states';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
// import { ApiConstants, LocalStorageConstants } from '../../../utils/constants';
import { HttpClient } from '@angular/common/http';
import { AppState } from 'src/app/store/app.states';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoaderComponent } from '../../components/loader.component';
import { environment } from 'src/environments/environment';
import { ReuploadResponse } from 'src/app/models/reupload';
import { ApiConstants, CurrentActions } from 'src/app/constants';
import * as appState from '../../store/app.states';
import { UpdateKycImage } from 'src/app/store/actions/reupload.action';
import { Router } from '@angular/router';
// import { RefreshLoanAndProfileData } from '../../../store/actions/core.action';


  declare var MediaRecorder: any;
  interface MediaRecorderOptions {
    mimeType?: string;
    audioBitsPerSecond?: number;
    videoBitsPerSecond?: number;
    bitsPerSecond?: number;
  }
  
  // Add this type declaration for BlobEvent
  interface BlobEvent extends Event {
    readonly data: Blob;
  }
  
  type MediaRecorder = {
    new (stream: MediaStream, options?: MediaRecorderOptions): MediaRecorder;
    readonly mimeType: string;
    readonly state: 'inactive' | 'recording' | 'paused';
    ondataavailable: (event: BlobEvent) => void;
    onerror: (event: Event) => void;
    onpause: (event: Event) => void;
    onresume: (event: Event) => void;
    onstart: (event: Event) => void;
    onstop: (event: Event) => void;
    start(timeslice?: number): void;
    stop(): void;
    resume(): void;
    pause(): void;
    requestData(): void;
  };
  
  @Component({
    selector: 'app-shop-environment-video',
    templateUrl: './shop-environment-video.component.html',
    styleUrls: ['./shop-environment-video.component.css']
  })
export class ShopEnvironmentVideo implements OnInit{

  @ViewChild('video', { static: false }) video: ElementRef;
  videoStream: MediaStream | null = null;
  mediaRecorder: MediaRecorder | null = null;
  recordedChunks: any = [];
  recordedVideoUrl: string | null = null;
  showNext:boolean=false
  autoSend=false
  recording=false
  autoSendTimeout: any;
  recordingDuration = 0;
  private BASE_URL = environment.sdkApiUrl;
  private recordingCompleted$ = new Subject<void>();
  recordingTimer$;
  partnerSlug:any
  dialogRef: MatDialogRef<LoaderComponent>;
  public mobile: string;
  private REUPLOAD_URL = environment.reuploadUrl

  private currentStateSubscription: Subscription;
  public reupload$: Observable<ReuploadResponse>;
  videoOn:boolean=false


  constructor(private store: Store<AppState>,
  private http: HttpClient,
  public dialog: MatDialog,
  private ngZone: NgZone,
  private router:Router
  ){
    this.reupload$ = this.store.pipe(select(appState.getProfile));
    const params: any = window.location.pathname.split('/');
    this.mobile = params[1];
  }

    ngOnInit(): void {
      this.currentStateSubscription = this.reupload$.subscribe((data:any) => {
        if (data) { 
          if(data.current_action !==CurrentActions.SHOP_VIDEO){
            this.router.navigate([this.mobile]);

          }
          else{
            //  this.startVideoStream()

             navigator.permissions.query({ name: 'camera' as PermissionName}).then((permissionStatus) => {
              if (permissionStatus.state === 'granted') {
                this.startVideoStream();
              } else if (permissionStatus.state === 'prompt') {
             
                navigator.mediaDevices
                  .getUserMedia({ video: { facingMode: 'environment' } })
                  .then(() => {
                    this.startVideoStream();
                  })
                  .catch((error) => {
                    console.error('Error accessing webcam:', error);
                  });
              } else {
                console.error('Camera permission is denied.');
              }
            });
          }
        }})
       
    }

    ngOnDestroy(): void {
      this.stopVideoStream();
      if (this.currentStateSubscription) {
        this.currentStateSubscription.unsubscribe();
      }
    }
  
  
    stopVideoStream() {
      if (this.videoStream) {
        const tracks = this.videoStream.getTracks();
        tracks.forEach((track) => track.stop());
        this.videoStream = null;
      }
    }

    startVideoStream() {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ video: {facingMode: 'environment'} })
          .then(stream => {
            this.video.nativeElement.srcObject = stream;
            this.video.nativeElement.play();
            this.videoStream = stream;
            setTimeout(()=>{
              this.videoOn=true
  
            },2000)
            this.setupMediaRecorder();
          })
          .catch(error => {
            console.error('Error accessing webcam:', error);
          });
      }
    
    }
  
    setupMediaRecorder() {
      if (this.videoStream) {
        this.mediaRecorder = new MediaRecorder(this.videoStream);
        this.mediaRecorder.ondataavailable = event => {
          if (event.data.size > 0) {
            this.recordedChunks.push(event.data);
          }
        };
        this.mediaRecorder.onstop = () => {
          this.recordedVideoUrl = URL.createObjectURL(new Blob(this.recordedChunks, { type: 'video/webm' }));
          if(this.autoSend){
            // this.sendVideo()
          }
        };
      }
    }
  
    startRecording() {
      this.recordingDuration = 0;
      this.showNext=false
      this.recording=true
      this.setupMediaRecorder()
      if (this.mediaRecorder && !this.mediaRecorder.state.includes('recording')) {
        this.recordedVideoUrl = null;
        this.mediaRecorder.start();
        this.autoSendTimeout= setTimeout(()=>{
          if(this.recording){
          this.autoSend=true
          this.stopRecording()
          }
        },15000)
        this.recordingTimer$ = timer(0, 1000).subscribe(() => {
          if (this.recordingDuration < 15) {
            this.recordingDuration++;
          } else {
            this.recordingCompleted$.next();
          }
        });
  
        this.recordingCompleted$.subscribe(() => {
          // this.stopRecording();
        });
      }
    }
  
    stopRecording() {
      this.recording=false
      clearTimeout(this.autoSendTimeout);
      if (this.mediaRecorder && this.mediaRecorder.state.includes('recording')) {
        this.showNext=true
        this.mediaRecorder.stop();
      }
      if (this.recordingTimer$) {
        this.recordingTimer$.unsubscribe();
      }
    }
  



sendVideo() {
  const blob = new Blob(this.recordedChunks, { type: 'video/webm' });
  const videoFile = new File([blob], 'recorded_video.webm', { type: 'video/webm' });

  const formData = new FormData();
  formData.append('reupload[base64_shop_video]', videoFile);

  const headers = {
  };
  this.dialogRef = this.dialog.open(LoaderComponent, {
    width: '250px',
    data: 'loading'
  });

  this.http.patch(`${this.REUPLOAD_URL}${ApiConstants.API_UPDATE_KYC}${this.mobile}`,  formData ).pipe(
    finalize(() => {
      this.ngZone.run(() => {
        if (this.dialogRef) {
          this.dialogRef.close();
        }
      });
    })
  ).subscribe((res:any)=>{
    this.router.navigate([CurrentActions.THANK_YOU])

  })
  

  this.recordedChunks = [];
  this.showNext = false;
  this.autoSend = false;
}

}
