import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { exhaustMap, catchError, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import {
  ReuploadActionTypes,
  FetchProfile,
  FetchProfileSuccess,
  UpdateKycImage,
  UpdateKycImageSuccess,
  UpdateKycImageFailure,
  FetchProfileFailure
} from '../actions/reupload.action';
import { ApiService } from '../../services/api.service';
import { ErrorDialogService } from 'src/app/services/error-dialog.service';

@Injectable()
export class ReuploadEffects {

  constructor(
    private actions: Actions,
    private apiService: ApiService,
    private router: Router,
    private errorDialogService:ErrorDialogService
  ) {}

  @Effect()
  FetchProfile = this.actions.pipe(
    ofType<FetchProfile>(ReuploadActionTypes.FetchProfile),
    map(action => action.payload),
    exhaustMap((data: any) => {
      return this.apiService.fetchReuploadProfile(data.mobile).pipe(
        map(response => new FetchProfileSuccess(response)),
        catchError(error => of(new FetchProfileFailure(error)))
      );
    })
  );

  @Effect({ dispatch: false })
  FetchProfileFailure = this.actions.pipe(
    ofType(ReuploadActionTypes.FetchProfileFailure),
    map((action: FetchProfileFailure) => action)
    , tap((data) => {
      if (data) {
        this.router.navigate(['']);
      }
    })
  );

  @Effect()
  UpdateKycImage = this.actions.pipe(
    ofType<UpdateKycImage>(ReuploadActionTypes.UpdateKycImage),
    map(action => action.payload),
    exhaustMap((data: any) => {
      return this.apiService.updateDocument(data.mobile, data.document).pipe(
        map(response => new UpdateKycImageSuccess(response)),
        catchError(error => of(new UpdateKycImageFailure(error)))
      );
    })
  );

  @Effect({ dispatch: false })
  UpdateKycImageFailure = this.actions.pipe(
    ofType(ReuploadActionTypes.UpdateKycImageFailure),
    map((action: FetchProfileFailure) => action)
    , tap((data:any) => {
      if (data) {
        console.log('data',data)
        this.errorDialogService.showError(data.payload.error.error[0])
      }
    })
  );
}
