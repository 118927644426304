import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../store/app.states';
import { ErrorDialog } from '../models/utils';
import { ErrorDialogComponent } from '../components/error-dialog.component';
import * as fromApp from '../store/app.states';

@Injectable()
export class ErrorDialogService {
  // Observables
  errorDialog$: Observable<ErrorDialog>;

  // Subscriptions
  errorDialogSubscription: Subscription;

  // Local variables
  dialogRef: MatDialogRef<ErrorDialogComponent>;

  constructor(private store: Store<AppState>, public dialog: MatDialog) {
    this.errorDialog$ = this.store.pipe(select(fromApp.getErrorDialog));
  }

  public init(): void {
    this.errorDialogSubscription = this.errorDialog$.subscribe((errorDialog) => {
      if (errorDialog != null && errorDialog.showDialog) {
        this.showError(errorDialog.errorText);
      }
    });
  }

  public destroy(): void {
    if (this.errorDialogSubscription) {
      this.errorDialogSubscription.unsubscribe();
    }
  }

  showError(errorText: string = 'Error...'): void {
    this.dialogRef = this.dialog.open(ErrorDialogComponent, {
      width: '250px',
      data: errorText
    });
  }

  hideError(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
