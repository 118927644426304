import { Injectable, Output, EventEmitter } from '@angular/core';
import { AppState } from '../store/app.states';
import { Store } from '@ngrx/store';

@Injectable()
export class CoreService {

  constructor(private store: Store<AppState>) { }

  @Output() backPressed: EventEmitter<any> = new EventEmitter();

  onBackPressed = (): void => {
    this.backPressed.emit();
  }

}
