import { Routes } from '@angular/router';
import { PathConstants, LocalStorageConstants, CurrentActions } from './constants';
import { ReuploadPhotoComponent } from './views/reupload-photo/reupload-photo.component';
import { NotFoundPageComponent } from './views/not-found-page/not-found-page.component';
import { WaitWithoutActionComponent } from './views/wait-without-action/wait-without-action.component';
import { ShopPhoto } from './views/shop-photo/shop-photo.component';
import { ShopEnvironmentVideo } from './views/shop-environment-video/shop-environment-video.component';
import { DigilockerConsent } from './views/digilocker/digilocker-consent.component';

export const ROUTES: Routes = [
  // Main redirect
  {
    path: `${CurrentActions.THANK_YOU}`,
    component: WaitWithoutActionComponent,
    data: { from: CurrentActions.THANK_YOU }
  },
  {
    path: `:${LocalStorageConstants.MOBILE_KEY}`,
    component: ReuploadPhotoComponent, 
    children: [
      {
        path: `${CurrentActions.SHOP_PHOTO}`,
        component: ShopPhoto,
        data: { from: CurrentActions.SHOP_PHOTO }
      },
      {
        path: `${CurrentActions.SHOP_VIDEO}`,
        component: ShopEnvironmentVideo,
        data: { from: CurrentActions.SHOP_VIDEO }
      },
      {
        path: `${CurrentActions.DIGILOCKER_CONSENT}`,
        component: DigilockerConsent,
        data: { from: CurrentActions.DIGILOCKER_CONSENT }
      },
    ]
  },
  {
    path: '',
    component: NotFoundPageComponent
  }

];
