import { Component, OnDestroy, OnInit } from '@angular/core';
import { ErrorDialogService } from './services/error-dialog.service';
import { LoaderService } from './services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnDestroy {
  constructor(private loaderService: LoaderService,
              private errorDialogService: ErrorDialogService) {}

  ngOnInit(): void {
    this.loaderService.init();
    this.errorDialogService.init();
  }

  ngOnDestroy(): void {
    this.loaderService.destroy();
    this.errorDialogService.destroy();
  }
}
