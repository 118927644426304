import { Action } from '@ngrx/store';

export enum ErrorDialogActionTypes {
  CloseErrorDialog = '[Core] Close Error Dialog'
}

export class CloseErrorDialog implements Action {
  readonly type = ErrorDialogActionTypes.CloseErrorDialog;

  constructor() {}
}

export type ErrorDialogActionsUnion =
  | CloseErrorDialog;
